import { httpCall } from "../httpCalls";

export const getUtilizationChartDataAPI = async ({
  startDate,
  endDate,
  member_ids,
}) => {
  const utilization_chart_data_response = await httpCall({
    type: "GET",
    url: "utilizations",
    data: {
      from_date: startDate,
      to_date: endDate,
      member_ids,
    },
  });
  const utilizatoin_chart_data = utilization_chart_data_response.data.data;
  return utilizatoin_chart_data;
};

export const getTimeEntryDashboardDataAPI = async ({
  startDate,
  endDate,
  resource_ids,
}) => {
  const timeEntryResponse = await httpCall({
    type: "GET",
    url: "dashboard/time-entries",
    data: {
      start_date: startDate,
      end_date: endDate,
      resource_ids,
    },
  });
  const timeEntriesData = timeEntryResponse.data;
  return timeEntriesData;
};
